/**
 * @param {Object} switches 11111101
 */
const days = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
export function formatSwitches(switches) {
  let result = [];
  let startIndex = 0;
  let endIndex = 0;
  let isWork = false;
  do {
    for (let i = startIndex; i < switches.length; i++) {
      let c = switches.charAt(i);
      if (c == '1') {
        isWork = true;
        startIndex = i;
        break
      }
    }

    for (let i = startIndex + 1; i < switches.length; i++) {
      let c = switches.charAt(i);
      if (c == '0') {
        endIndex = i - 1 > startIndex ? i - 1 : startIndex;
        break
      }
      endIndex = i;
    }
    if (isWork) {
      if (startIndex < endIndex) {
        if (result.length > 0) {
          result.push("、")
        }
        result.push(days[startIndex] + " ~ " + days[endIndex]);
      } else if (startIndex == endIndex) {
        if (result.length > 0) {
          result.push("、")
        }
        result.push(days[startIndex]);
      }
      isWork = false;
    }
    if (endIndex + 1 >= switches.length) {
      break;
    }
    startIndex = endIndex + 1;
    endIndex = startIndex;
  } while (endIndex < switches.length)

  return result.join("")

}
