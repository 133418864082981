<template>
  <div class="page full-height pb-50">
    <van-swipe :autoplay="3000" indicator-color="white" class="page-header__swipe">
      <van-swipe-item class="page-header__swipe-item" :key="index" v-for="(image, index) in bannerImages">
        <van-image class="swipe-img" fit="fill"
                   :src="image"/>
      </van-swipe-item>
    </van-swipe>


    <div class="outlet-name">
      {{outletDetail.storeName}}
    </div>
    <van-cell-group v-if="tags.length > 0" class="outlet__tags">
      <van-tag plain type="primary" size="large" :key="index" v-for="(tag, index) in tags">{{tag}}</van-tag>
    </van-cell-group>
    <van-cell icon="cash-back-record" class="" title="价格" :value="currSkuInfo.salePrice" v-if="currSkuInfo"></van-cell>
    <van-cell icon="photo-o" class="" title="VR全景浏览" is-link :url="this.outletDetail.panoramicUrl" v-if="this.outletDetail.panoramicUrl != null"></van-cell>
    <van-cell class="outlet__location" :title="location" icon="location-o"  @click="openLocation" is-link/>
    <van-cell title="工作时间" :value="workTime"></van-cell>
    <van-cell-group title="网点每周工作时间(点击查看每天具体工作时间)" v-if="this.outletDetail.storeWorkTime != null" >


    </van-cell-group>

<!--    <van-tabs color="#07c160" title-active-color="#333" title-inactive-color="#999">-->
<!--      <van-tab title="网点介绍">-->
<!--        <div class="outlet__rich-content">-->
<!--         <p style="text-align: center;color:#999999"> 暂无介绍</p>-->
<!--        </div>-->

<!--      </van-tab>-->
<!--      <van-tab title="网点评价">-->
<!--        <div class="outlet__rich-content">-->
<!--          <p style="text-align: center;color:#999999"> 暂无评价</p>-->
<!--        </div>-->
<!--      </van-tab>-->
<!--    </van-tabs>-->

    <van-goods-action>
      <van-goods-action-button color="#fb6646" :to="`/inspection/book/${outletDetail.id}`" text="立即预约"  />
    </van-goods-action>
  </div>
</template>

<script>
  import {Swipe, SwipeItem, Image, Cell, CellGroup,  Notify, Tag,GoodsAction, GoodsActionButton} from 'vant';
  import {storeDetailService} from '@/service/store';
  import {formatSwitches} from "@/utils/workTimeUtils";

  export default {
    name: "outletDetail",
    components: {
      vanImage: Image,
      vanSwipe: Swipe,
      vanSwipeItem: SwipeItem,
      vanCell: Cell,
      vanCellGroup: CellGroup,

      vanTag:Tag,
      // vanTab: Tab,
      // vanTabs: Tabs,
      vanGoodsAction:GoodsAction,
      vanGoodsActionButton:GoodsActionButton
    },
    data() {
      return {
        outletDetail: {},
        dayOfWeek: '',
        amStartTime: null,
        amEndTime: null,
        pmStartTime: null,
        pmEndTime: null,
        currSkuInfo:null,
      }
    },
    computed: {
      workTime(){
        if (this.outletDetail.workTimes) {
          return this.outletDetail.workTimes.map(item => {
            return formatSwitches(item.switches) + " " + item.startTime + " ~ " + item.endTime;
          }).join(" , ");
        }
        return '周一 ~ 周五 9:00 - 17:00'
      },
      tags(){
        return this.outletDetail.tags ? this.outletDetail.tags.split("|") : [];
      },
      bannerImages() {
        if(this.outletDetail.bannerImg != null){
          return JSON.parse(this.outletDetail.bannerImg);
        } else {
          return [this.outletDetail.coverImg];
        }

      },
      location() {
        const item = this.outletDetail;
        return `${item.provinceStr ? item.provinceStr : ''}${item.cityStr ? item.cityStr : ''}${item.areaStr ? item.areaStr : ''}${item.address}`;
      },
    },
    methods: {
      loadOutletDetail() {
        const id = this.$route.params.storeId;
        storeDetailService(id, {
          channelId:4,
          categoryId: 3,
        })
          .then(resp => {
            if (resp.success) {
              this.outletDetail = resp.data;
              const sku = resp.data.skus.find(item => item.channelId == 4);
              if (sku) {
                this.currSkuInfo = sku;
              }
            } else {
              Notify(resp.message);
            }
          })
          .catch(error => {
            console.log(error);
          })
      },
      openLocation() {
        if (!this.outletDetail.address) {
          return;
        }
        const param = {
          latitude: parseFloat(this.outletDetail.lat), // 纬度，浮点数，范围为90 ~ -90
          longitude: parseFloat(this.outletDetail.lng), // 经度，浮点数，范围为180 ~ -180。
          name: this.outletDetail.storeName, // 位置名
          address: this.outletDetail.address, // 地址详情说明
          scale: 12
        };
        this.$wechat.openLocation(param);
      },
    },
    mounted() {
      const weekArray = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'];
      this.dayOfWeek = weekArray[new Date().getDay()];
      this.loadOutletDetail();
    }


  }
</script>

<style lang="less" type="text/less" scoped>
  @import "../assets/less/variable";

  .page-header__swipe {
    width: 100%;
    height: 250px;
    overflow: hidden;

    &-item {
      width: 100%;
      height: 100%;

      .swipe-img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .outlet-name {
    padding: 15px;
    font-size: 18px;
    line-height: 22px;
    color: #2b2b2b;
    background-color: #FFFFFF;
    margin-bottom: 5px;
  }

  .outlet__location {
    align-items: center;
  }

  .outlet__workday {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 15px;
  }

  .outlet__day {
    display: inline-flex;
    justify-content: center;
    line-height: 16px;
    font-size: 14px;
    padding: 8px 5px;
    border-radius: 4px;

    &--on {
      background: @theme-color;
      color:#FFF;
    }

    &--off {
      background: @gray-6;
      color: #FFFFFF;
    }
  }
  .outlet__tags{
    padding:15px;
    display: flex;
    margin-bottom: 5px;
  }

  .outlet__rich-content {
    padding: 15px;
    margin-bottom: 5px;
  }

  .outlet__work-time {
    display: flex;
    justify-content: space-around;
    padding-bottom: 15px;
  }

  .outlet__day-of-week {
    background: #07c160;
  }

  .outlet__time {
    width: 120px;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-align: center;

    background-color: #f6f8f7;
    color: @gray-6;
  }

  .book-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
  }
</style>
